import { Col, Row } from "antd";
import Image from "next/image";
import Link from "next/link";
import Framework from "../../../components/Content/Framework";
import Typography from "../../../components/Typography";
import { Button, Content, SideImage, Container } from "./styled";

export default function DesignHomeOffice() {
  return (
    <Container>
      <Row gutter={0}>
        <Col xs={24} md={12}>
          <SideImage>
            <Image
              src="/images/home_page/on-the-blog.png"
              layout="fill"
              quality={70}
              objectFit="cover"
              alt="Featured in Phoenix Home and Garden"
            />
          </SideImage>
        </Col>
        <Col xs={24} md={12}>
          <Content>
            <Typography
              fontType="header"
              color="darkGray2"
              fontWeight="bold"
              fontSize="2rem"
              fontFamily="Lora"
            >
              2025 Trends in the Custom Storage Solution Industry
            </Typography>
            <Typography
              fontFamily="Roboto medium"
            >
              

As we head into 2025, the custom storage solution industry continues to innovate, blending functionality with aesthetics to meet the diverse needs of homeowners. Whether it’s reimagining closets, designing multi-functional home offices, or optimizing garage spaces, here are the key trends shaping the year ahead.
            </Typography>
            <Link passHref href="/blog/post/classy-closets-featured-in-phoenix-home-garden/">
              <Button variant="outline">ON THE BLOG</Button>
            </Link>
          </Content>
        </Col>
      </Row>
    </Container>
  );
}
